/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    "use strict";
    var TimLewis = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                //Header Vars
                var mainHeader = $('.banner'),
                    headerHeight = mainHeader.height();

                //Scrolling Vars
                var scrolling = false,
                    previousTop = 0,
                    currentTop = 0,
                    scrollOffset = 250;

                $(window).on('resize', function () {
                    headerHeight = mainHeader.height();
                });

                function checkStickyNavigation(currentTop) {
                    if (currentTop > scrollOffset) {
                        mainHeader.addClass('affixed');
                    } else {
                        mainHeader.removeClass('affixed');
                    }
                }

                var autoStickyHeader = function () {
                    currentTop = $(window).scrollTop();
                    checkStickyNavigation(currentTop);
                    previousTop = currentTop;
                    scrolling = false;
                };

                $(window).on('scroll', function () {
                    if (!scrolling) {
                        scrolling = true;
                        if (!window.requestAnimationFrame) {
                            setTimeout(autoStickyHeader, 100);
                        } else {
                            requestAnimationFrame(autoStickyHeader);
                        }
                    }
                });

                var stickyForm = new Sticky('#community-form', {
                    'marginTop': headerHeight + 16,
                    'stickyClass': 'sticky',
                    'wrap': true,
                    // 'stickyFor': 768
                });
                var stickyTabs = new Sticky('.content-tabs .nav', {
                    'marginTop': headerHeight + 16,
                    'stickyClass': 'sticky',
                    'wrap': true,
                    'stickyFor': 768
                });

                window.addEventListener('resize', function () {
                    stickyForm.update();
                    stickyTabs.update();
                });

                // stickyHeader.update();
                stickyForm.update();
                stickyTabs.update();


                /* ==================================
                // Mobile Nav
                // =============================== */

                var navToggle = $('.nav-toggle, .close-menu'),
                    navOpen = false,
                    navMenu = $('.header-nav');

                navToggle.click(function (e) {
                    // return false;
                    e.preventDefault();

                    if (navOpen === false) {
                        navOpen = true;
                        navMenu.addClass('nav-open');
                    } else {
                        navOpen = false;
                        navMenu.removeClass('nav-open');
                    }
                });


                var communityMapUrl = $('#community-map-link').data('remote');
                $('.modal-body').load(communityMapUrl).data('remote');


                $.ajax({
                    url: communityMapUrl,
                    type: 'GET',
                    success: function (res) {
                        //console.log(res);
                    }
                });

                // Header Background Slider
                var bgImages = document.querySelectorAll('.background-images .background-image');
                var bgimagescount = bgImages.length;

                if (bgimagescount !== 0) {
                    var bgGalleryIndex = 0;

                    var changeSlide = function () {
                        var backgroundImageActive = bgImages[bgGalleryIndex];

                        if (bgGalleryIndex < bgImages.length) {
                            $('.background-images .background-image').removeClass('active');
                            bgGalleryIndex++;
                            backgroundImageActive.classList.add('active');
                        } else {
                            bgGalleryIndex = 0;
                        }
                    };
                    setInterval(changeSlide, 5000);
                }

                // Add smooth scrolling to all links
                $("a").on('click', function (event) {

                    // Make sure this.hash has a value before overriding default behavior
                    if (this.hash !== "") {
                        // Prevent default anchor click behavior
                        event.preventDefault();

                        // Store hash
                        var hash = this.hash;
                        var scrollPos = $(hash).offset().top;

                        // $('#community-page-nav .active').removeClass('active');
                        // this.classList.add('active');

                        // Using jQuery's animate() method to add smooth page scroll
                        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                        $('html, body').animate({
                            scrollTop: scrollPos - (mainHeader.height() + 20)
                        }, 300, function () {
                            // Add hash (#) to URL when done scrolling (default click behavior)
                            // window.location.hash = hash;
                        });
                    } // End if
                });
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = TimLewis;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);


    // $(document).ready(function ($) {
    //
    // });


})(jQuery); // Fully reference jQuery after this point.



